import React, { useEffect, useState } from "react";
import Bottombar from "../Bottombar";
import { FaLocationArrow } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillPhone, AiFillClockCircle } from "react-icons/ai";
import "../../styles/Contact.scss";
import { db } from "../../FirebaseConfig";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
const Contact = () => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [desc, setDesc] = useState();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const submitForm = async () => {
    if (name && phone && desc) {
      setShowButton(false);
      console.log(name, phone, desc);
      // await addDoc(collection(db, "Contact"), {
      //   name: name,
      //   phone: phone,
      //   description: desc,
      // });
      alert("Success");
    } else {
      alert("Fill all fields");
    }
  };
  const getContactData = async () => {
    setLoad(true);
    let docs = [];
    const docRef = doc(db, "ContactData", "T8QY2vFUEQ0fxA5xuPwV");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      docs.push(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setData(docs);
    setLoad(false);
  };
  useEffect(() => {
    getContactData();
  }, []);
  return (
    <>
      <div className="contact_parent">
        <div className="col_1">
          {load === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              />
            </div>
          ) : data[0] ? (
            <>
              <div className="contact_blocks">
                <div className="contact_icon">
                  <MdEmail />
                </div>
                <div>
                  <div className="contact_h1">Email</div>
                  <div className="contact_h2">{data[0].email}</div>
                </div>
              </div>
              <div className="contact_blocks">
                <div className="contact_icon">
                  <AiFillPhone />
                </div>
                <div>
                  <div className="contact_h1">Phone Number</div>
                  <div className="contact_h2">{data[0].phone}</div>
                </div>
              </div>
              <div className="contact_blocks">
                <div className="contact_icon">
                  <AiFillClockCircle />
                </div>
                <div>
                  <div className="contact_h1">Open Hours</div>
                  <div className="contact_h2">{data[0].time}</div>
                </div>
              </div>
              <div className="contact_blocks">
                <div className="contact_icon">
                  <FaLocationArrow />
                </div>
                <div>
                  <div className="contact_h1">Location</div>
                  <div className="contact_h2">{data[0].location}</div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="col_2">
          <p>Grab the Opportunity!!! Let's Team Up</p>
          <h4>Tell us More about yourself & what you’ve got in your mind</h4>
          <div className="form">
            <input
              className="input"
              placeholder="Your Name"
              required
              type="text"
              onChange={(event) => setName(event.target.value)}
            />
            <span className="input-border"></span>
          </div>
          <div className="form">
            <input
              className="input"
              placeholder="Your Phone"
              required
              type="text"
              onChange={(event) => setPhone(event.target.value)}
            />
            <span className="input-border"></span>
          </div>
          <div className="form">
            <input
              className="input"
              placeholder="Tell us about your Business/Query"
              required
              type="text"
              onChange={(event) => setDesc(event.target.value)}
            />
            <span className="input-border"></span>
          </div>
          <div className="button_parent">
            {showButton ? (
              <button onClick={submitForm} style={{ cursor: "pointer" }}>
                <div className="svg-wrapper-1">
                  <div className="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="#fff"
                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <span>Submit</span>
              </button>
            ) : (
              <p className="altButtonText">
                Your query is successfully submitted. We will revert you as soon
                as possible
              </p>
            )}
          </div>
        </div>
      </div>
      <Bottombar />
    </>
  );
};

export default Contact;
