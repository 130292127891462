import React from "react";
import "../../styles/WhyGama.scss";
const WhyGama = () => {
  return (
    <div className="about">
      <div className="about_image"></div>
      <div className="about_text">
        <p className="large_heading_2">Why Gama Advisory?</p>
        <div className="large_heading">Why</div>
        <div className="large_heading"> Gama Advisory?</div>
        <div>
          {" "}
          • Highly Professional Team – Well experienced and customer centric<br/><br/>
          • High Client Value - We collaborate closely with our customers to drive
          their diversity, equity and inclusion efforts with targeted research,
          trends analysis and products that are designed to address the needs of
          a diverse population.<br/><br/>
          • World Class Global Support – We provide
          “Best-in-class” support to our clients from India, Dubai and Poland
          round the clock.
        </div>
      </div>
    </div>
  );
};

export default WhyGama;
