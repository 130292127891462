import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Navbar.scss";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import logo from "../assets/adv_logo.png";
const Navbar = () => {
  const style = {
    borderRadius: "14px",
    padding: "10px 10px",
    margin: " 0px 5px",
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (path) => {
    setAnchorEl(null);
    navigate(path);
  };
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = (path) => {
    setAnchorEl2(null);
    navigate(path);
  };
  return (
    <>
      <div className="header-navbar">
        <div className="navbar_hamburger">
          <div>
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              sx={{ marginTop: "20px" }}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                sx={{ width: "100vw" }}
                onClick={() => {
                  handleClose("/");
                }}
              >
                Home
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("/about");
                }}
              >
                About
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("/service/business-setup");
                }}
              >
                UAE Business Setup
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("/service/immigration");
                }}
              >
                Poland Immigration
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href = "https://www.gamajobs.com";
                }}
              >
                Job Consultancy
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("/contact");
                }}
              >
                Contact us
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("/blog");
                }}
              >
                Blog
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} style={{ aspectRatio: "1", height: "50px" }} alt="" />
        </div>
        <div className="navbar_links">
          <Link to="/" style={style} className="h">
            Home
          </Link>
          <Link to="/about" style={style}>
            About
          </Link>
          <Link
            id="basic-button"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            style={style}
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
          >
            Services
          </Link>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            elevation={2}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{ color: "grey" }}
              onClick={() => handleClose2("/service/business-setup")}
            >
              UAE Business Setup
            </MenuItem>
            <MenuItem
              sx={{ color: "grey" }}
              onClick={() => handleClose2("/service/immigration")}
            >
              Poland Immigration
            </MenuItem>
            <MenuItem
              sx={{ color: "grey" }}
              onClick={() =>
                (window.location.href = "https://www.gamajobs.com")
              }
            >
              Job Consultancy
            </MenuItem>
          </Menu>
          <Link to="/contact" style={style}>
            Contact
          </Link>
          <Link to="/blog" style={style}>
            Blog
          </Link>
        </div>
        <div>
          <button onClick={() => navigate("/contact")}>Quote</button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
