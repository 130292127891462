import React from "react";
import "../../styles/BusinessSetup.scss";
import "../../styles/PolandImmigration.scss";
import Bottombar from "../Bottombar";
import { useNavigate } from "react-router-dom";
const PolandImmigration = () => {
  const navigate = useNavigate()
  // const handleClickScroll = () => {
  //   const element = document.getElementsByClassName("bs_image");
  //   if (element) {
  //     console.log("ey");
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  return (
    <div className="pol_body">
      <div className="bs_header">
        <div className="bs_text">
          <h1>POLAND IMMIGRATION</h1>
        </div>
      </div>

      <div className="po_heading">Poland Immigration</div>
      <h4 className="pol_subHeading">Poland Immigration from India</h4>
      <div className="bs_content_1">
        Poland granted the most visas to foreign skilled workers in Europe in
        2017. In recent years, it has also been the case that immigrants are
        labelled as job searchers who intend to build a future in the new
        country. Poland is predicted to see an exponential increase in the
        immigrant population in the future years, as the country's GDP rises.
        UAE.
        <br />
        Many Indians are choosing Poland as a destination for immigration for
        the following reasons:
        <br />
        Suitable for people with customer service and IT Skills/abilities.
        <br />
        The average work week is 40 hours long (8 hours every day).
        <br />
        After a year of employment, an employee is entitled to 20 days of paid
        vacation.
        <br />
        Unemployment is really low.
        <br />
        The Average salary is increasing in the country.
      </div>
      <div className="bs_heading_2">
        How can One Immigrate to Poland for Work?
      </div>
      <div className="bs_content_2">
        Citizens of the EEA nations, which comprise the European Union, Norway,
        Liechtenstein, Iceland, and Switzerland, do not need to worry about
        obtaining a work visa in Poland. You can work lawfully in Poland for six
        months if you are from Armenia, Belarus, Georgia, Moldova, Russia, or
        Ukraine. Other countries, such as India, have established regulations
        and procedures that must be obeyed.
        <br />
        <br />
        <h4>The Work Permit Guidelines:</h4>
        <br />
        You must legitimise your stay in Poland in order to obtain a work
        permit.
        <br />
        A residence is required for a legal stay. You cannot work throughout
        your residency.
        <br />
        The work permit is issued in response to a request from a potential
        employer.
        <br />
        Your work permit is only valid for the company and job for which you
        were engaged.
        <br />
        The Six types of Work Permit in Poland Are Given below
        <br />
        Type A – Employed in a company based in Poland
        <br />
        Type B – Part of management, general partner/Proxy
        <br />
        Type C –Working for a non polish employer but is delegated to a branch
        for 30 days or a facility in Poland
        <br />
        Type D - Working for a non-polish employer and is in Poland for a
        service
        <br />
        Type E - Working for a non-polish employer and is aligned to Poland for
        a time of three to six months
        <br />
        Type S – Seasonal work permit. It was introduced in 2018
      </div>
      <div className="bs_heading_2">
        How To Apply for Temporary Residency in Poland?
      </div>
      <div className="bs_content_3">
        The correct approach is to first apply for temporary residency and then
        for a work permit in Poland. Alternatively, you can apply for both a
        temporary residency and a work permit simultaneously. Temporary
        residency and work permits are valid for three years, although much
        depends on the terms of your employment contract.
        <br />
        Documents Required for Temporary Residency in Poland
        <br />
        Application for the temporary residence permit
        <br />
        Four recent colored photographs
        <br />
        A valid travel document
        <br />
        Proof of sufficient funds
        <br />
        Health insurance coverage of treatment in Poland
        <br />
        Confirming a residence in Poland lease agreement etc.
        <br />
        Tax returns.
        <br />
        <br />
        <div className="bs_heading_2">STUDY IN POLAND</div>
        <h4>Overview</h4>
        <div className="pol_content">
          Poland is one of Europe's most attractive countries, with numerous
          educational facilities and institutions that attract students from all
          over the world. There are various reasons why students prefer Poland
          to finish their studies over other nations. Poland is a Baltic Sea
          country in eastern Europe famous for its mediaeval architecture and
          Jewish heritage. The capital, Warsaw, has shopping and nightlife, as
          well as the Warsaw Uprising Museum, which honors the city's resistance
          to German occupation during WWII.
          <br />
          Poland provides a student-friendly environment for students from all
          over the world, . If you wish to study in Poland in any of the fields
          of medical, engineering, tourism, management, or to pursue your PhD,
          we can provide you with complete assistance in obtaining a Poland
          student visa. Poland is one of the few European Union countries that
          have maintained a stable GDP growth rate despite the global economic
          downturn that has slowed GDP growth in numerous countries. Poland is a
          country that is quickly developing.
        </div>
        <div className="bs_heading_2">Benefits of Studying in Poland</div>
        <div className="pol_content">
          1.) In comparison to other European countries, Poland has a low cost
          of living. A budget of 200-250 USD is sufficient to cover lodging,
          food, and travel costs.
          <br />
          2.) In comparison to other EU countries, tuition fees at Polish
          universities are also affordable. Tuition rates range from 1000 to
          4000 USD each year. Medicine and engineering are examples of more
          expensive courses, with annual tuition expenses of around 4000 USD.
          The cost of a hotel management course may exceed 5,000 USD that is
          balanced by the salary earned by students during their internships.
          <br />
          3.) Other European countries, such as Germany, are easier to reach
          from Poland. Many graduates from Polish universities apply for jobs in
          German enterprises because of the attractive career chances in
          numerous areas. The two countries' cultures and environments are
          comparable, so students will have little trouble adjusting. Because it
          is easy to obtain a visa for these countries from Poland, it serves as
          an entry point for Germany and other European nations.
          <br />
          4.) In Poland, there are numerous options for leisure and pleasure.
          There are fantastic movie theatres, museums, gyms and pools, as well
          as bars and pubs. The entrance charge to these places is not always
          prohibitively expensive. Student discounts are also available in most
          places.
          <br />
          5.) Polish universities have tie ups with many prestigious colleges
          and organizations around the world, allowing students to take
          advantage of internship opportunities there. Universities in Poland
          collaborate with universities in the United Kingdom, the United
          States, Australia, Switzerland, and Greece. These chances are
          beneficial to students' growth while also allowing them to earn money
          to meet their living expenses.
        </div>
        <div className="bs_heading_2">Popular Courses for Study in Poland</div>
        <div className="pol_content">
          MBA in Poland
          <br />
          MBBS in Poland
          <br />
          Civil Engineering
          <br />
          Electrical Engineering
          <br />
          Electronics and Information Technology
          <br />
          Aerospace Engineering
          <br />
          Architectural Engineering
          <br />
          Telecommunication and Computer Science
          <br />
          Information and Technology
          <br />
          Top Universities in Poland
          <br />
          University of Bialystok
          <br />
          Casimir the Great University
          <br />
          University of Gdańsk
          <br />
          Jagiellonian University
          <br />
          John Paul II Catholic University
          <br />
          Maria Curie-Sklodowska University
          <br />
          University of Lodz
          <br />
          University of Warmia and Mazury
          <br />
          Opole University
          <br />
          Adam Mickiewicz University
          <br />
          University of Rzeszów
          <br />
          University of Silesia
          <br />
          University of Szczecin
          <br />
          Nicolaus Copernicus University
          <br />
          University of Warsaw
          <br />
          Cardinal Stefan Wyszyński University in Warsaw
          <br />
          University of Wrocław
          <br />
          University of ZielonaGóra
          <br />
          Jan Kochanowski University
          <br />
          <h4 className="pol_ending">
            If you are looking Poland study visa from India, then you can
          </h4>
          <button className="contact-us" onClick={()=>navigate("/contact")}>Contact us</button>
        </div>
      </div>
      <Bottombar />
    </div>
  );
};

export default PolandImmigration;
