import React from "react";
import "../../styles/Billboard.scss";

const Billboard = () => {
  return (
    <div className="billboard">
      <div className="billboard_1">
        <p>Highly Professional Team</p>
      </div>
      <div className="billboard_2">
        <p>High Client Value</p>
      </div>
      <div className="billboard_3">
        <p>World Class Global Support</p>
      </div>
    </div>
  );
};

export default Billboard;
