import { getDownloadURL, listAll, ref } from "firebase/storage";
import { React, useState, useEffect } from "react";
import storage from "../../FirebaseConfig";

const Advert = () => {
  const [image, setImage] = useState();
  var listRef = ref(storage, `/files/`);
  const onClick = () => {
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          console.log("folderRef");
          console.log(folderRef);
        });
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            console.log(`url ${url}`);
            setImage(url);
          });
          console.log(image);
        });
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };
  useEffect(() => {
    onClick();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {image ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="advert"
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default Advert;
