import React from "react";
import "../../styles/BusinessSetup.scss";
import Bottombar from "../Bottombar";
import { useNavigate } from "react-router-dom";
const BusinessSetup = () => {
  const navigate = useNavigate();

  // const handleClickScroll = () => {
  //   const element = document.getElementsByClassName("bs_image");
  //   if (element) {
  //     console.log("ey");
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  return (
    <div className="uae_body">
      <div className="bs_header">
        <div className="bs_text">
          <h1>UAE BUSINESS SETUP</h1>
        </div>
        {/* <a className="bs_click_here" href="#bs_image">
          <p className="click_here">Click Here</p>
          <div>
            <ArrowDownwardIcon />
          </div>
        </a> */}
        {/* <img
          className="bs_image"
          id="bs_image"
          src="https://img.freepik.com/premium-photo/summer-holiday-nobody-officebusiness-visual-data-analyzing-technology-creative-computer-software_102957-330.jpg?w=2000"
          alt="bs_header_image"
        /> */}
      </div>

      <div className="bs_heading">Business Setup Consultants & Experts!</div>
      <div className="bs_content_1">
        Starting a company or business in Dubai is simple and time-saving with
        Gamaadvisory services LLP. We can assist you in forming a company on the
        mainland, offshore, or in a Free Zone. we have a track record of
        providing professional advice on company formation and registration in
        Dubai and the United Arab Emirates. Our experienced Business Set Up
        agents will assist you with company formation and registration in Dubai,
        as well as other business and company-related services throughout the
        UAE.
        <br />
        <br />
        We provide comprehensive business / company setup services for
        everything a new business / company requires in the UAE. Gamaadvisory
        services is the best choice when you are looking to start or open a
        business in Dubai, Mainland business setup, or free zone business setup
        with promising results. Business setup in Dubai with us is the real
        deal, as we make sure to walk you through each step from beginning to
        end, including trademark registration, business bank account, and PRO.
        Our consultants are here to assist you and make your dreams come true!
      </div>
      <div className="bs_heading_2">Business Setup In Dubai</div>
      <div className="bs_content_2">
        Gamaadvisory is one of the largest and most reputable business setup
        consulting firms in the United Arab Emirates. Starting a business can be
        difficult. You require the assistance of business setup consultants who
        have years of experience and know which path to take to ensure your
        success. And we, at GamaAdvisory, are one of the leading consultants who
        will not disappoint you. Our consultants can help you set up your
        company or business based on how you want to prosper now and in the
        future.
        <br />
        <br />
        <h4>Business Setup by Gama Advisory- Lowest Fees Guaranteed</h4>
        <br />
        <br />
        When you choose us as your business consultant, our experience and
        expertise team twill undoubtedly be a plus! Anyone who wants to start an
        offshore company must first open a bank account. Setting it up in a
        foreign country can be difficult if you are unfamiliar with the local
        banking laws. Our consultants can also assist you in opening a bank
        account without difficulty! We have a comprehensive list of banks with
        which you could collaborate! Above all, our agents can assist you in
        locating the ideal office space that will bring you a large number of
        customers. You can consult us for your Offshore Company setup queries.
        Gama advisory assures you assistance in terms of advice and support for
        a successful Company setup in UAE.
      </div>
      <div className="bs_heading_2">
        Easy Steps To Company Formation / Registration in Dubai UAE
      </div>
      <div className="bs_content_3">
        Our consultants have extensive experience in the formation and
        registration of new businesses. Our consultants are the best and top
        professionals because they provide a one-stop solution for all your
        company / business requirements, from legal formalities to
        infrastructure and promotion. Our consultants are the best in the
        business consulting industry. Our goal is to provide the best possible
        service to our clients and to assist them in every way possible as they
        establish their company or business. Our firm provides the best
        consulting services to its clients. We follow all applicable rules,
        regulations, and guidelines. We make the process of forming and
        registering your business simple and painless. Our expert consultants
        are dedicated to providing you with the best solutions. We make sure
        that you do not face any major obstacles when establishing your company
        or business in Dubai.
        <br />
        <br />
        At Gamaadvisory with us, we know how to assist you in establishing and
        marketing your company in the best possible location. We have assisted
        both international and domestic clients with our business setup
        consulting services. We look out for your best interests and provide you
        with a solution that is tailored to your needs. Our company consultants
        work tirelessly to provide you with free business zone consultations.
        we'll help you succeed because we know how to handle everything from
        promotion to paperwork to infrastructure. Our rates are also
        significantly lower, making us a company that can assist small, medium,
        and large businesses. Gama Advisory has been helping Global
        entrepreneurs start new businesses For over a decade, we have assisted
        Indian entrepreneurs in realising their dream of establishing a business
        in Dubai. A businessman's major constraints include finance and tax. You
        won't have to worry about taxes if you start a business in Dubai because
        the city-state is a tax haven. The Dubai administration has taken
        several steps in recent years to make Dubai the best place to start a
        business. You have access to a top pool of opportunities in Dubai that
        you won't find anywhere else. Gamaadvisory has assisted international
        entrepreneurs in establishing new companies. So what are you waiting
        for? Call us now. Our best and most experienced business setup
        consultant or expert will provide you with the best solutions. Contact
        us to get your business formation.
        <br />
        <br />
        <h4>
          {" "}
          Gama Advisory has been helping Global entrepreneurs start new
          businesses
        </h4>
        <br />
        <br />
        For over a decade, we have assisted Indian entrepreneurs in realising
        their dream of establishing a business in Dubai. A businessman's major
        constraints include finance and tax. You won't have to worry about taxes
        if you start a business in Dubai because the city-state is a tax haven.
        The Dubai administration has taken several steps in recent years to make
        Dubai the best place to start a business. You have access to a top pool
        of opportunities in Dubai that you won't find anywhere else.
        Gamaadvisory has assisted international entrepreneurs in establishing
        new companies. So what are you waiting for? Call us now. Our best and
        most experienced business setup consultant or expert will provide you
        with the best solutions. Contact us to get your business formation.
        <br />
        <br />
        <button className="contact-us" onClick={() => navigate("/contact")}>
          Contact us
        </button>
      </div>

      <Bottombar />
    </div>
  );
};

export default BusinessSetup;
