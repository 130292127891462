import React from "react";
import "../../styles/About.scss";
import Bottombar from "../Bottombar";
import AboutHeader from "./AboutHeader";
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <AboutHeader />
      <div className="about_exp"> We Have 10+ Years of Experience</div>
      <div className="about_exp_text">
        <img src={require("../../assets/exp.png")} alt="exp_img" />
        <div>
          Gamaadvisory is one of the largest and most reputable business setup
          consulting firms in India. Starting a business can
          be diffitult. You require the assistance of business setup consultants
          who have years of experience and know which path to take to ensure
          your success. And we, at GamaAdvisory, are one of the leading
          consultants who will not disappoint you. Our consultants can help you
          set up your company or business based on how vou want to prosper now
          and in the future.
        </div>
      </div>
      <div className="quotes">
        <img
          src={require("../../assets/quotes.png")}
          alt="quotes"
          height="60px"
        />
        <div>
          Whatever Your Business May be, We Will Assist You From Beginning To
          End
        </div>
        <img
          src={require("../../assets/quotes.png")}
          alt="quotes"
          height="60px"
          style={{transform:"scaleX(-1)",marginLeft:"10px",marginTop:"-10px"}}
        />
      </div>
      <div className="expertise_title">OUR EXPERTISE AREA</div>

      <div className="expertise_blocks">
        <div className="block_1"style={{cursor:"pointer"}} onClick={()=>{navigate("/service/business-setup")}}>
          {/* <img
            src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
            alt=""
          />
          <p>BUSINESS SETUP CONSULTANTS</p> */}
          <p>BUSINESS SETUP CONSULTANTS</p>
        </div>
        <div className="block_2" style={{cursor:"pointer"}} onClick={()=>{navigate("/service/immigration")}}>
          {/* <img src={require("../../assets/plane.png")} alt="" /> */}
          <p>POLAND IMMIGRATION & VISA</p>
        </div>
        <div className="block_3" style={{cursor:"pointer"}} onClick={()=>{ window.location.href = "https://www.gamajobs.com"}}>
          {/* <img src={require("../../assets/recruitment.png")} alt="" /> */}
          <p>E2E RECRUITMENT CONSULTING</p>
        </div>{" "}
      </div>
      <div className="world">
        <p>We have a Global network of clients</p>
      </div>
      <div className="princi_text">All Because Of Our 3 Principles</div>

      <div className="princi">
        <div className="block_1_princi">
          <p>Highly Professional Team</p>
        </div>
        <div className="block_2_princi">
          <p>High Client Value</p>
        </div>
        <div className="block_3_princi">
          <p>World Class Global Support</p>
        </div>{" "}
      </div>
      <Bottombar />
    </>
  );
};

export default About;
