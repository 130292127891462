import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import "../../styles/dialog.scss";

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(0,0,20,0.5)",
  },
}));

export default function ExampleDialogComponent() {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("seen")) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          borderRadius: "16px",
          border: "1px solid #f58b39 ",
        },
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={() => {}}
    >
      <DialogContent
        style={{
          textAlign: "center",
          padding: "60px 40px",
        }}
      >
        <h2 style={{ color: "#f58b39" }}>
          Welcome to Gama Advisory Services!!
        </h2>
        <p style={{ color: "#145f76", marginBottom: "40px" }}>
          Contact us for any queries, we will be happy to help you!!
        </p>
        <button
          className="button2"
          onClick={() => {
            sessionStorage.setItem("seen", true);
            setOpen(false);
          }}
        >
          OK
        </button>
      </DialogContent>
    </Dialog>
  );
}
