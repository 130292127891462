import React, { useState, useEffect } from "react";
import AboutSlideItem from "./AboutSlideItem";
import SwiperCore, { Autoplay } from "swiper";
import { doc, getDoc } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { db } from "../../FirebaseConfig";

const AboutHeader = () => {
  SwiperCore.use([Autoplay]);
  const [data, setData] = useState([]);
  const getMembers = async () => {
    let docs = [];
    const docRef = doc(db, "About", "teamNames");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      docs.push(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setData(docs);
  };
  console.log(data);
  useEffect(() => {
    getMembers();
  }, []);

  return (
    <div className="mainHeader">
      <div className="mainHeading">We’re Changing The Whole Game.</div>
      <Swiper
        className="about_slider"
        breakpoints={{
          // when window width is >= 640px
          1300: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {data[0]
          ? data[0].team.map((item, index) => {
              return (
                <SwiperSlide key={index} className="about_slide_item">
                  <AboutSlideItem
                    props={{
                      image: item.image,
                      name: item.name,
                      title: item.desi,
                    }}
                  />
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
    </div>
  );
};

export default AboutHeader;
