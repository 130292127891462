import React from "react";
import "../styles/Bottombar.scss";
import ScrollToTop from "react-scroll-to-top";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
const Bottombar = () => {
  return (
    <>
      <div>
        <ScrollToTop
          smooth
          color="rgb(23, 71, 87)"
          style={{ textAlign: "center" }}
        />
      </div>
      <div className="bottombar">
        <div className="b_icons">
          <TwitterIcon
            onClick={() =>
              (window.location.href = "https://twitter.com/gamajobs")
            }
            style={{ margin: "0px 10px", cursor: "pointer" }}
          />
          <LinkedInIcon
            onClick={() =>
              (window.location.href =
                "https://www.linkedin.com/company/gama-jobs")
            }
            style={{ margin: "0px 10px", cursor: "pointer" }}
          />
          <FacebookIcon
            onClick={() =>
              (window.location.href = "https://www.facebook.com/gamajobs")
            }
            style={{ margin: "0px 10px", cursor: "pointer" }}
          />
          <InstagramIcon
            onClick={() =>
              (window.location.href = "https://www.instagram.com/gamajobs")
            }
            style={{ margin: "0px 10px", cursor: "pointer" }}
          />
        </div>
        <div className="b_credits">
          {" "}
          &#169; Copyright at Gama Advisory Services
        </div>
      </div>
    </>
  );
};

export default Bottombar;
