import React from "react";
import "../../styles/AboutSlideItem.scss";
const AboutSlideItem = ({ props }) => {
  return (
    <div className="cover_asi">
      <img src={props.image} alt={props.name} />
      <div className="asi_name">{props.name}</div>
      <div className="asi_title">{props.title}</div>
    </div>
  );
};

export default AboutSlideItem;
