import { CircularProgress } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../FirebaseConfig";
import "../../styles/Blog.scss";
import Bottombar from "../Bottombar";

import ActionAreaCard from "./BlogCard";

const Blog = () => {
  const [cats, setCats] = useState([]);
  const [selectedId, setSelectedId] = useState("All");
  const [blogs, setBlogs] = useState([]);
  const [load, setLoad] = useState(false);

  const getBlogs = async (id) => {
    setLoad(true);
    console.log(id);
    const docs = [];
    const docRef = collection(db, "BlogPosts");
    if (id == "All") {
      const q = query(docRef);
      const docSnap = await getDocs(q);
      docSnap.forEach((data) => {
        docs.push(data);
      });
    } else {
      const q = query(docRef, where("category", "==", id));
      const docSnap = await getDocs(q);
      docSnap.forEach((data) => {
        docs.push(data);
      });
    }
    setBlogs(docs);
    setLoad(false);

    console.log(blogs);
  };
  const getCategories = async () => {
    setLoad(true);

    const docs = [];
    const docRef = collection(db, "Category");
    const docSnap = await getDocs(docRef);
    docSnap.forEach((data) => {
      docs.push(data);
    });
    setCats(docs);
    setSelectedId("All");
    getBlogs("All");
    setLoad(false);
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <div>
        <div className="blog_header_parent">
          <div className="blog_header">Blogs</div>
        </div>
        <div className="categories_tab">
          {blogs ? (
            <div
              onClick={() => {
                getBlogs("All");
                setSelectedId("All");
              }}
              style={{
                color: selectedId === "All" ? "white" : "Black",
                backgroundColor:
                  selectedId === "All" ? "var(--orange)" : "white",
              }}
            >
              ALL
            </div>
          ) : null}
          {cats?.map((item, index) => (
            <div
              style={{
                color: selectedId === item.id ? "white" : "Black",
                backgroundColor:
                  selectedId === item.id ? "var(--orange)" : "white",
              }}
              onClick={() => {
                getBlogs(item.id);
                setSelectedId(item.id);
              }}
              key={index}
            >
              {item.data().name.toString().toUpperCase()}
            </div>
          ))}
          {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={initialName}
              onChange={handleChange}
              displayEmpty
              sx={{ textAlign: "center" }}
            >
              {cats?.map((item, index) => (
                <MenuItem
                  onClick={() => {
                    getBlogs(item.id);
                    setSelectedId(item.id);
                    setInitialName(item.data().name);
                  }}
                  key={index}
                  value={item.data().name}
                >
                  {item.data().name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </div>
        {load === true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "500px",
            }}
          >
            <CircularProgress sx={{ marginTop: "20px" }} />
          </div>
        ) : (
          <div className="blog_item_wrapper">
            {blogs.length!==0?blogs.map((item, index) => {
              return (
                <div key={index} className="card">
                  <ActionAreaCard props={item.data()}></ActionAreaCard>
                </div>
              );
            }):<p className="noBlogs">No Blogs For this Category</p>}
          </div>
        )}
      </div>
      <Bottombar />
    </>
  );
};

export default Blog;
