import React, { useRef } from "react";
import "../../styles/Carousel.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";

const Carousel = () => {
  SwiperCore.use([Autoplay]);
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div>
      <Swiper
        className="carousel"
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        spaceBetween={0}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide className="slide">
          <div>Setup Your Business in UAE</div>
          <div className="miniButtons">
            <div>Freezone</div>
            <div>Mainland</div>
            <div>offshore</div>
          </div>
          <div className="button-div">
            <button style={{cursor:"pointer"}} onClick={()=>navigate("/contact")}  className="cta">
              <span>Grab Opportunity</span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div>Poland Immigration Services</div>
          <div className="miniButtons">
            <div>Freezone</div>
            <div>Mainland</div>
            <div>offshore</div>
          </div>
          <div className="button-div">
            <button style={{cursor:"pointer"}} onClick={()=>navigate("/contact")} className="cta">
              <span>Grab Opportunity</span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div>Job Consultancy Services</div>
          <div className="miniButtons">
            <div>Freezone</div>
            <div>Mainland</div>
            <div>offshore</div>
          </div>
          <div className="button-div">
            <button style={{cursor:"pointer"}} onClick={()=>navigate("/contact")}  className="cta">
              <span>Grab Opportunity</span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </div>
        </SwiperSlide>
        ...
      </Swiper>
    </div>
  );
};

export default Carousel;
