import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.scss";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Blog from "./components/Blog/Blog";
import BusinessSetup from "./components/Services/BusinessSetup";
import PolandImmigration from "./components/Services/PolandImmigration";
import ScrollToTop from "./components/scroll";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/service/business-setup" element={<BusinessSetup />} />
          <Route path="/service/immigration" element={<PolandImmigration />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
