import React from "react";
import "../../styles/Headings.scss";
import { Link, useNavigate } from "react-router-dom";

const Headings = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="heading">
        {" "}
        <div>
          <div>
            <p>Our Services</p>
          </div>
        </div>
      </div>
      <div> </div>
      <div className="headings1">
        <div className="dubai_setup">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/service/business-setup")}
            src={
              "https://images.unsplash.com/photo-1668030377049-84dbb95aa007?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80"
            }
            alt=""
            srcSet=""
          />
          <div className="dubai_setup_text">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/service/business-setup")}
            >
              UAE BUSINESS SETUP
            </h1>
            <p style={{ fontSize: "1.85vw" }}>
              {" "}
              "Dubai, the bustling metropolis of the UAE, has emerged as a
              global business hub and an attractive destination for
              entrepreneurs and investors worldwide. "Starting a company or
              business in Dubai is simple and time-saving with Gama Advisory
              Services LLP. We can assist you in forming a company on the
              mainland, offshore, or in a Free Zone." Get a{" "}
              <Link to={"/contact"}>Quote</Link>, we will be happy to help you."
            </p>
          </div>
        </div>
        <div className="poland">
          <div className="poland_im">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/service/immigration")}
            >
              POLAND IMMIGRATION
            </h1>
            <p>
              {" "}
              "Poland is one of Europe's most attractive countries, with
              numerous educational facilities and institutions that attract
              students from all over the world. Poland is predicted to see an
              exponential increase in the immigrant population in the future
              years, as the country's GDP rises."
            </p>
          </div>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/service/immigration")}
            src={
              "https://images.unsplash.com/photo-1564041549956-3ad6fa9f5517?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            alt=""
            srcSet=""
          />
        </div>
        <div className="job_consultancy">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "https://gamajobs.com")}
            src={
              "https://img.freepik.com/free-photo/business-man-financial-inspector-secretary-making-report-calculating-checking-balance-internal-revenue-service-inspector-checking-document-audit-concept_1423-126.jpg?w=1800&t=st=1675197108~exp=1675197708~hmac=7299502defc862e658240329d4b6d96398a91ea5ad1bd4cf41fc157b05e682f8"
            }
            alt=""
            srcSet=""
          />
          <div className="dubai_setup_text">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "https://gamajobs.com")}
            >
              JOB CONSULTANCY
            </h1>
            <p>
              {" "}
              "We are a team of experienced professionals from various domain
              working towards the common goal of providing our clients with the
              most talented resources at sustainable cost. We provide E2E
              Digital Recruitment and HR Consulting Services to MNCs, MSMEs, IT,
              Finance, Banking, Investments, BPO, Pharma, FMCG, Legal,
              Insurance, Education, Marketing, Hotel, Travel and Manufacturing
              Industries."
            </p>
          </div>
        </div>
      </div>

      {
        // Second Styling
      }
      <div className="headings2">
        <div className="dubai_setup_2">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/service/business-setup")}
            src={
              "https://images.unsplash.com/photo-1668030377049-84dbb95aa007?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80"
            }
            alt=""
            srcSet=""
          />
          <div className="dubai_setup_text">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/service/business-setup")}
            >
              UAE BUSINESS SETUP{" "}
            </h1>
            <p>
              {" "}
              "Starting a company or business in Dubai is simple and time-saving
              with Gamaadvisory services LLP. We can assist you in forming a
              company on the mainland, offshore, or in a Free Zone."
            </p>
          </div>
        </div>
        <div className="dubai_setup_2">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/service/immigration")}
            src={
              "https://images.unsplash.com/photo-1564041549956-3ad6fa9f5517?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            }
            alt=""
            srcSet=""
          />
          <div className="dubai_setup_text">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/service/immigration")}
            >
              POLAND IMMIGRATION
            </h1>
            <p>
              {" "}
              "Poland is one of Europe's most attractive countries, with
              numerous educational facilities and institutions that attract
              students from all over the world. Poland is predicted to see an
              exponential increase in the immigrant population in the future
              years, as the country's GDP rises."
            </p>
          </div>
        </div>
        <div className="dubai_setup_2">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "https://gamajobs.com")}
            src={
              "https://img.freepik.com/free-photo/business-man-financial-inspector-secretary-making-report-calculating-checking-balance-internal-revenue-service-inspector-checking-document-audit-concept_1423-126.jpg?w=1800&t=st=1675197108~exp=1675197708~hmac=7299502defc862e658240329d4b6d96398a91ea5ad1bd4cf41fc157b05e682f8"
            }
            alt=""
            srcSet=""
          />
          <div className="dubai_setup_text">
            {" "}
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "https://gamajobs.com")}
            >
              JOB CONSULTANCY
            </h1>
            <p>
              {" "}
              "We are a team of experienced professionals from various domain
              working towards the common goal of providing our clients with the
              most talented resources at sustainable cost. We provide E2E
              Digital Recruitment and HR Consulting Services to MNCs, MSMEs, IT,
              Finance, Banking, Investments, BPO, Pharma, FMCG, Legal,
              Insurance, Education, Marketing, Hotel, Travel and Manufacturing
              Industries."
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headings;
