import React from "react";
import Advert from "./Advert";
import Billboard from "./Billboard";
import Carousel from "./Carousel";
import Headings from "./Headings";
import "../../styles/Home.scss";
import WhyGama from "./WhyGama";
import Bottombar from "../Bottombar";
import ExampleDialogComponent from "./dialog";
const Home = () => {
  return (
    <>
      <ExampleDialogComponent />
      <Carousel />
      <div className="home">
        <Billboard />
        <Headings />
        <Advert />
        <WhyGama />
        <Bottombar />
      </div>
    </>
  );
};

export default Home;
