import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import HTMLReactParser from "html-react-parser";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ActionAreaCard({ props }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card
        elevation={0}
        onClick={handleOpen}
        sx={{
          borderRadius: "15px",
          boxShadow: "2px 2px 10px #dedede, -2px -2px 10px #e9e9e9",
        }}
      >
        <CardActionArea>
          <div className="blog_image">
            <img
              src={
                props.content.match(
                  /\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/
                )?props.content.match(
                  /\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/
                )[1]:null
              }
              alt="This is Blog Image"
            />
          </div>
          <CardContent>
            <div
              style={{
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "600",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              // component="div"
            >
              {props.title}
            </div>
            <div
              className="blogCaption"
              style={{
                marginTop: "15px",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                // display: "inline-block",
                // whiteSpace: "nowrap",
                fontSize: "14px",
                width: "100%",
              }}
            >
              {props.content
                .replaceAll(/<img [^>]*src="[^"]*"[^>]*>/gm, "")
                .toString()
                .trim().length < 200
                ? HTMLReactParser(
                    props.content
                      .replaceAll(/<img [^>]*src="[^"]*"[^>]*>/gm, "")
                      .toString()
                      .trim()
                  )
                : HTMLReactParser(
                    props.content
                      .replaceAll(/<img [^>]*src="[^"]*"[^>]*>/gm, "")
                      .toString()
                      .trim()
                      .substring(0, 200)
                      .concat("...")
                  )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", background: "white" }}
          elevation={0}
        >
          <Toolbar>
            <Typography sx={{ ml: 5, flex: 1,margin:"15px"}} variant="h3" component="div">
              {props.title}
            </Typography>
            <IconButton edge="end" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography sx={{ ml: 5, mr: 5 }}>
          {HTMLReactParser(props.content)}{" "}
        </Typography>
      </Dialog>
    </>
  );
}
